let ENV;
if (ENVIRONMENT === "mcc") {
    ENV = import("./env.mcc").then((module) => module.default);
}
else if (ENVIRONMENT === "nhn") {
    ENV = import("./env.nhn").then((module) => module.default);
}
else {
    throw new Error("Invalid environment configuration");
}
export default ENV;
